import './style.scss';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ErrorDialog from 'components/ErrorDialog';
import Loading from 'components/Loading';

import { TOAST } from 'constant';
import { toast } from 'layout';

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
// import { useForm, useFormState } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  getGeneralProjectProposalInfo,
  getReviewerInfo,
  submit,
  downloadDocxTemplate,
  downloadDocxFile,
  downloadPdfFile,
  downloadPdfTemplate,
} from 'apis/prequalificationReviews.api';
import {
  getAcadamicLevelShortHand,
  isMemberAllowedReview,
  toNonAccentVietnamese,
} from 'utils/func';
import { Dialog } from 'primereact/dialog';
import DetailInfo from '../DetailInfo';
import ResearchProductTable from '../ResearchProductTable';
import ResearchCapacityTable from '../ResearchCapacityTable';
import GeneralEvaluateTable from '../GeneralEvaluateTable';
import GeneralRequestTable from '../GeneralRequestTable';

export default function PrequalificationForm({
  isCreateForm,
  // eslint-disable-next-line no-unused-vars
  isDetailFrom,
  isUpdateFrom,
  disabled,
  isLoading,
  isErrorRes,
  errorRes,
  handleSubmit,
  onSubmit,
  control,
  // eslint-disable-next-line no-unused-vars
  setValue,
  // eslint-disable-next-line no-unused-vars
  getValues,
  watch,
  isDirty,
  dirtyFields,
  errors,
  isValid,
  isVisibleErrorDialog,
  setIsVisibleDialogError,
  reset,
  data,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');
  const downloadTemplateButtonRef = useRef();
  const downloadButtonRef = useRef();

  const [isVisibleSubmitDialog, setIsVisibleSubmitDialog] = useState(false);
  // #region GetData
  // general info projectProposal
  const { data: dataGeneralProjectProposalInfo, isLoading: isLoadingProjectProposal } = useQuery(
    ['prequalification-review', 'project-proposal', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) =>
      getGeneralProjectProposalInfo(_councilId, _projectProposalId)
  );
  const [generalProjectProposalInfo, projectType] = useMemo(
    () => [dataGeneralProjectProposalInfo?.data, dataGeneralProjectProposalInfo?.data?.projectType],
    [dataGeneralProjectProposalInfo]
  );
  // reviewer info
  const { data: dataReviewerInfo, isLoading: isLoadingReviewer } = useQuery(
    ['prequalification-review', 'reviewer', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) =>
      getReviewerInfo(_councilId, _projectProposalId)
  );
  const reviewerInfo = useMemo(() => dataReviewerInfo?.data, [dataReviewerInfo]);
  const {
    mutate: submitMutate,
    error: errorSubmit,
    isError: isErrorSubmit,
    isLoading: isLoadingSubmit,
  } = useMutation(submit);

  const handleSubmitReview = () => {
    setIsVisibleSubmitDialog(true);
  };

  const onConfirmSubmit = () => {
    const submitData = {
      projectProposalId,
      councilId,
      councilMemberId,
    };
    submitMutate(submitData, {
      onSuccess: () => {
        reset({}, { keepValues: true });
        toast(TOAST.SUCCESS, t('council.review.submitSuccess'));
        setIsVisibleSubmitDialog(false);
        setTimeout(() => {
          navigate('/project-proposal-defense');
        }, 1500);
      },
      onError: () => {
        setIsVisibleDialogError(true);
      },
    });
  };
  // #end region Data

  // #region Events

  const {
    refetch: handleDownloadDocxTemplate,
    isLoading: isDownloadDocxTemplateLoading,
    isFetching: isDownloadDocxTemplateFetching,
  } = useQuery(
    ['downloadDocxTemplatePrequalificationReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M01b - Phieu nhan xet danh gia so tuyen.docx';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxFile,
    isLoading: isDownloadDocxFileLoading,
    isFetching: isDownloadDocxFileFetching,
  } = useQuery(
    ['downloadDocxFilePrequalificationReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            reviewerInfo?.scientificProfile?.academicRank,
            reviewerInfo?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia so tuyen - ${academicLevelShorthand}${toNonAccentVietnamese(
            reviewerInfo?.fullname
          )}.docx`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: (error) => {
        // 490 is the status code for the error
        // when the scientist has not reviewed the online form yet
        if (error?.response?.status === 490) {
          toast(TOAST.ERROR, t('errorMessage.scientistNotReviewOnlineFormYet'));
        } else {
          toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
        }
      },
    }
  );

  const {
    refetch: handleDownloadPdfFile,
    isLoading: isDownloadPdfFileLoading,
    isFetching: isDownloadPdfFileFetching,
  } = useQuery(
    ['downloadPdfFilePrequalificationReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            reviewerInfo?.scientificProfile?.academicRank,
            reviewerInfo?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia so tuyen - ${academicLevelShorthand}${toNonAccentVietnamese(
            reviewerInfo?.fullname
          )}.pdf`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: async (error) => {
        // 490 is the status code for the error
        // when the scientist has not reviewed the online form yet
        if (error?.response?.status === 490) {
          toast(TOAST.ERROR, t('errorMessage.scientistNotReviewOnlineFormYet'));
        } else {
          toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
        }
      },
    }
  );
  const {
    refetch: handleDownloadPdfTemplate,
    isLoading: isDownloadPdfTemplateLoading,
    isFetching: isDownloadPdfTemplateFetching,
  } = useQuery(
    ['downloadPdfTemplatePrequalificationReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M01b - Phieu nhan xet danh gia so tuyen.pdf';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );
  const checkValidation = () => {
    if (!isValid) {
      toast(TOAST.ERROR, t('errorMessage.validationErrorMessage'));
    }
  };
  const footerConfirmSubmit = () => (
    <div>
      <Button
        label={t('formLayout.action.cancel')}
        className="w-7rem"
        severity="danger"
        onClick={() => setIsVisibleSubmitDialog(false)}
        autoFocus
      />
      <Button
        label={t('formLayout.action.ok')}
        className="w-7rem"
        severity="info"
        onClick={onConfirmSubmit}
      />
    </div>
  );
  return (
    <div className="bg-white p-5">
      {(isLoading || isLoadingReviewer || isLoadingProjectProposal) && <Loading />}
      {isErrorRes && (
        <ErrorDialog
          title={t('council.review.create')}
          isError={isErrorRes}
          error={errorRes}
          visible={isVisibleErrorDialog}
          setVisible={setIsVisibleDialogError}
        />
      )}

      <div className="flex justify-content-end">
        <Button
          label={t('council.review.exportFile')}
          icon={`pi ${
            isDownloadDocxFileLoading || isDownloadDocxFileFetching
              ? 'pi-spin pi-spinner'
              : 'pi-file'
          }`}
          className="block w-auto h-3rem border-none"
          severity="info"
          onClick={(event) => downloadButtonRef.current.toggle(event)}
          disabled={isDownloadDocxFileLoading || isDownloadDocxFileFetching}
        />
        <Button
          label={t('council.review.downloadTemplate')}
          icon={`pi ${
            isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching
              ? 'pi-spin pi-spinner'
              : 'pi-file'
          }`}
          className="block w-auto h-3rem border-none ml-1"
          severity="info"
          onClick={(event) => downloadTemplateButtonRef.current.toggle(event)}
          disabled={isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching}
        />
      </div>
      <Menu
        ref={downloadButtonRef}
        model={[
          {
            label: t('council.review.exportPdf'),
            icon: 'pi pi-file-pdf',
            command: handleDownloadPdfFile,
            disabled: isDownloadPdfFileLoading || isDownloadPdfFileFetching,
          },
          {
            label: t('council.review.exportDocx'),
            icon: 'pi pi-file-word',
            command: handleDownloadDocxFile,
            disabled: isDownloadDocxFileLoading || isDownloadDocxFileFetching,
          },
        ]}
        popup
      />
      <Menu
        ref={downloadTemplateButtonRef}
        model={[
          {
            label: t('council.review.downloadPdfTemplate'),
            icon: 'pi pi-file-pdf',
            command: handleDownloadPdfTemplate,
            disabled: isDownloadPdfTemplateLoading || isDownloadPdfTemplateFetching,
          },
          {
            label: t('council.review.downloadDocxTemplate'),
            icon: 'pi pi-file-word',
            command: handleDownloadDocxTemplate,
            disabled: isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching,
          },
        ]}
        popup
      />
      {(isLoading || isLoadingProjectProposal || isLoadingReviewer || isLoadingSubmit) && (
        <Loading />
      )}
      {isErrorRes && (
        <ErrorDialog
          title={t('council.review.update')}
          isError={isErrorRes}
          error={errorRes}
          visible={isVisibleErrorDialog}
          setVisible={setIsVisibleDialogError}
        />
      )}
      {isErrorSubmit && (
        <ErrorDialog
          title={t('council.review.update')}
          isError={isErrorSubmit}
          error={errorSubmit}
          visible={isVisibleErrorDialog}
          setVisible={setIsVisibleDialogError}
        />
      )}
      <Dialog
        visible={isVisibleSubmitDialog}
        header={<p className="text-blue-500">{t('council.submit.confirmationTitle')}</p>}
        position="center"
        style={{ width: '410px' }}
        onHide={() => setIsVisibleSubmitDialog(false)}
        footer={() => footerConfirmSubmit()}
      >
        <p className="text-lg py-3">{t('council.submit.confirmationSubmitMessage')}</p>
      </Dialog>
      <div className="p-fluid prequalification-review-create">
        <div className="m-0 bg-white p-5 p-fluid">
          <h3 className="mb-5 text-4xl text-center">
            <span className="line-height-3 font-semibold">{t('council.m01b.title')}</span>
            <br />
            <span className="font-normal">{t('council.m01.sciTechProject')}</span>
          </h3>
        </div>
        <DetailInfo
          generalProjectProposalInfo={generalProjectProposalInfo}
          reviewerInfo={reviewerInfo}
        />
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-8 relative">
            <div className="m01b-a">
              <h4>{t('council.m01b.a.label')}</h4>
              <div className="m01b-a1">
                <GeneralRequestTable
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  projectType={projectType}
                  disabled={disabled}
                />
              </div>
              <div className="m01b-a2">
                <ResearchProductTable
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  projectType={projectType}
                  disabled={disabled}
                />
              </div>
              <div className="m01b-a3">
                <ResearchCapacityTable
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  projectType={projectType}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="m01b-b">
              <GeneralEvaluateTable
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                projectType={projectType}
                disabled={disabled}
              />
            </div>

            {/* // day la footer */}
            <div className="grid text-lg mt-8">
              <div className="col-7 px-8">
                <span>{t('council.m01.c.commitment')}</span>
              </div>
              <div className="col-5 text-center">
                <p className="mb-0">{t('signature.date')}</p>
                <p className="font-semibold mb-0">{t('signature.reviewer')}</p>
                <p className="mb-7">{t('signature.fullnameAndSignature')}</p>
                <p className="font-bold">{reviewerInfo?.fullname}</p>
              </div>
            </div>

            <div className="flex justify-content-end mt-8">
              <Button
                label={t('formLayout.action.close')}
                severity="danger"
                type="button"
                onClick={() => navigate('/project-proposal-defense')}
                className="mr-2 w-8rem"
              />
              {(isCreateForm || isUpdateFrom) && (
                <Button
                  label={t('formLayout.action.save')}
                  severity="info"
                  type="submit"
                  className="mr-2 w-8rem"
                  onClick={checkValidation}
                  disabled={!(isDirty && Object.keys(dirtyFields).length)}
                />
              )}
              {isUpdateFrom && (
                <Button
                  label={t('formLayout.submit')}
                  severity="success"
                  type="button"
                  onClick={handleSubmitReview}
                  className="w-8rem"
                  disabled={
                    // eslint-disable-next-line react/prop-types
                    !!data?.isSubmitted ||
                    (isDirty && Object.keys(dirtyFields).length) ||
                    // eslint-disable-next-line react/prop-types
                    data?.reviewDeadline < new Date().toISOString() ||
                    !isMemberAllowedReview(reviewerInfo?.councilMembers?.[0])
                  }
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

PrequalificationForm.propTypes = {
  isCreateForm: PropTypes.bool,
  isUpdateFrom: PropTypes.bool,
  isDetailFrom: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  isErrorRes: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  errorRes: PropTypes.shape({}),
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  watch: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  dirtyFields: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  isValid: PropTypes.bool.isRequired,
  isVisibleErrorDialog: PropTypes.bool.isRequired,
  setIsVisibleDialogError: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

PrequalificationForm.defaultProps = {
  isLoading: false,
  watch: null,
  isCreateForm: false,
  isUpdateFrom: false,
  isDetailFrom: false,
  disabled: false,
};
