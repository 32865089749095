import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  downloadDocxFile,
  downloadDocxTemplate,
  downloadPdfFile,
  downloadPdfTemplate,
  getReviewerInfo,
  getReviewDetail,
} from 'apis/acceptanceReviews.api';

import Loading from 'components/Loading';
import { TOAST } from 'constant';
import { toast } from 'layout';

import { DetailInfo } from 'features/FinancialReview/components';
import { EvaluationForm, EvaluateTable } from 'features/AcceptanceReview/components';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { EditorInput } from 'components/FormControl';

import { getAcadamicLevelShortHand, toNonAccentVietnamese } from 'utils/func';

export default function AcceptanceReviewDetail() {
  // #region Data
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');
  const downloadButtonRef = useRef();
  const downloadTemplateButtonRef = useRef();

  const { data: acceptanceReviewData, isLoading: isLoadingAcceptanceReview } = useQuery(
    ['acceptance-review', councilId, projectProposalId],
    () => getReviewDetail(councilMemberId, projectProposalId, councilId)
  );
  const acceptanceReview = useMemo(() => acceptanceReviewData?.data, [acceptanceReviewData]);

  const {
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  // Fill available data to form
  useEffect(() => {
    if (acceptanceReview?.acceptanceReview) {
      const { acceptanceReview: review } = acceptanceReview;
      const {
        scoreTarget,
        scoreContent,
        scoreApproach,
        scoreProcess,
        scoreApplicationProduct,
        scorePublishedProduct,
        scoreTrainingProduct,
        scoreReportQuality,
      } = review;
      const scoreResponse = scoreTarget + scoreContent + scoreApproach + scoreProcess;
      const scoreQuantityAndQuality = parseInt(
        scoreApplicationProduct + scorePublishedProduct + scoreTrainingProduct,
        10
      );
      setValue('reviewMeet', review.reviewMeet);
      setValue('reviewProduct', review.reviewProduct);
      setValue('reviewReportQuality', review.reviewReportQuality);
      setValue('reviewConclusion', review.reviewConclusion);
      setValue('score.scoreApproach', review.scoreApproach);
      setValue('score.scoreTarget', scoreTarget);
      setValue('score.scoreContent', scoreContent);
      setValue('score.scoreProcess', scoreProcess);
      setValue('score.scoreResponse', scoreResponse);
      setValue('score.scoreApplicationProduct', scoreApplicationProduct);
      setValue('score.scorePuslishedProduct', scorePublishedProduct);
      setValue('score.scoreTrainingProduct', scoreTrainingProduct);
      setValue('score.scoreQuantityAndQuality', scoreQuantityAndQuality);
      setValue('score.scoreReportQuality', scoreReportQuality);

      reset({}, { keepValues: true });
    }
  }, [acceptanceReview, acceptanceReview?.acceptanceReview, setValue]);

  // A. General Information
  const { data: infoData } = useQuery(['acceptance-reviewer'], () =>
    getReviewerInfo(projectProposalId, councilId)
  );
  const info = useMemo(() => infoData?.data, [infoData]);
  // #endregion

  // #region Events

  const {
    refetch: handleDownloadPdfFile,
    isLoading: isDownloadPdfFileLoading,
    isFetching: isDownloadPdfFileFetching,
  } = useQuery(
    ['downloadPdfFileAcceptanceReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            info?.user?.scientificProfile?.academicRank,
            info?.user?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia nghiem thu - ${academicLevelShorthand}${toNonAccentVietnamese(
            info?.user?.fullname
          )}.pdf`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: (error) => {
        // 490 is the status code for the error
        // when the scientist has not reviewed the online form yet
        if (error?.response?.status === 490) {
          toast(TOAST.ERROR, t('errorMessage.scientistNotReviewOnlineFormYet'));
        } else {
          toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
        }
      },
    }
  );

  const {
    refetch: handleDownloadDocxFile,
    isLoading: isDownloadDocxFileLoading,
    isFetching: isDownloadDocxFileFetching,
  } = useQuery(
    ['downloadDocxFileAcceptanceReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            info?.user?.scientificProfile?.academicRank,
            info?.user?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia nghiem thu - ${academicLevelShorthand}${toNonAccentVietnamese(
            info?.user?.fullname
          )}.docx`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: (error) => {
        // 490 is the status code for the error
        // when the scientist has not reviewed the online form yet
        if (error?.response?.status === 490) {
          toast(TOAST.ERROR, t('errorMessage.scientistNotReviewOnlineFormYet'));
        } else {
          toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
        }
      },
    }
  );

  const {
    refetch: handleDownloadPdfTemplate,
    isLoading: isDownloadPdfTemplateLoading,
    isFetching: isDownloadPdfTemplateFetching,
  } = useQuery(
    ['downloadPdfTemplateAcceptanceReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M08 - Phieu nhan xet danh gia nghiem thu.pdf';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxTemplate,
    isLoading: isDownloadDocxTemplateLoading,
    isFetching: isDownloadDocxTemplateFetching,
  } = useQuery(
    ['downloadDocxTemplateAcceptanceReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M08 - Phieu nhan xet danh gia nghiem thu.docx';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );
  // #endregion

  return (
    <>
      {isLoadingAcceptanceReview && <Loading />}

      <div className="m-0 bg-white px-4 sm:px-4 md:px-4 lg:px-6 xl:px-8 mb-3 py-5 p-fluid relative financial-review-edit">
        <div className="flex gap-1 justify-content-end">
          <Button
            label={t('council.review.exportFile')}
            icon={`pi ${
              isDownloadPdfFileLoading ||
              isDownloadPdfFileFetching ||
              isDownloadDocxFileLoading ||
              isDownloadDocxFileFetching
                ? 'pi-spin pi-spinner'
                : 'pi-file'
            }`}
            className="block w-auto h-3rem border-none"
            severity="info"
            onClick={(event) => downloadButtonRef.current.toggle(event)}
            disabled={
              isDownloadPdfFileLoading ||
              isDownloadPdfFileFetching ||
              isDownloadDocxFileLoading ||
              isDownloadDocxFileFetching
            }
          />
          <Button
            // type="button"
            label={t('council.review.downloadTemplate')}
            icon={`pi ${
              isDownloadPdfTemplateLoading ||
              isDownloadPdfTemplateFetching ||
              isDownloadDocxTemplateLoading ||
              isDownloadDocxTemplateFetching
                ? 'pi-spin pi-spinner'
                : 'pi-file'
            }`}
            className="block w-auto h-3rem border-none"
            severity="info"
            onClick={(event) => downloadTemplateButtonRef.current.toggle(event)}
            disabled={
              isDownloadPdfTemplateLoading ||
              isDownloadPdfTemplateFetching ||
              isDownloadDocxTemplateLoading ||
              isDownloadDocxTemplateFetching
            }
          />
        </div>
        <Menu
          ref={downloadButtonRef}
          model={[
            {
              label: t('council.review.exportPdf'),
              icon: 'pi pi-file-pdf',
              command: handleDownloadPdfFile,
              disabled: isDownloadPdfFileLoading || isDownloadPdfFileFetching,
            },
            {
              label: t('council.review.exportDocx'),
              icon: 'pi pi-file-word',
              command: handleDownloadDocxFile,
              disabled: isDownloadDocxFileLoading || isDownloadDocxFileFetching,
            },
          ]}
          popup
        />
        <Menu
          ref={downloadTemplateButtonRef}
          model={[
            {
              label: t('council.review.downloadPdfTemplate'),
              icon: 'pi pi-file-pdf',
              command: handleDownloadPdfTemplate,
              disabled: isDownloadPdfTemplateLoading || isDownloadPdfTemplateFetching,
            },
            {
              label: t('council.review.downloadDocxTemplate'),
              icon: 'pi pi-file-word',
              command: handleDownloadDocxTemplate,
              disabled: isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching,
            },
          ]}
          popup
        />

        <div className="mb-5 flex justify-content-between align-items-center">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="VNU-HCM logo"
            className="ml-3 h-4rem md:h-5rem"
          />
          <div className="pl-2">
            {t('projectProposal.viewDetail.form')} M08 <br />
            {t('council.m08.projectCode')}
          </div>
        </div>
        <div className="text-center mb-6">
          <h3 className="font-bold mb-0">{t('council.m08.title')}</h3>
          <h3 className="mt-0">{t('council.m08.sciTechProject')}</h3>
        </div>

        <DetailInfo info={info} />

        <form autoComplete="off">
          <EvaluationForm
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            control={control}
            errors={errors}
          />

          <EvaluateTable
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            control={control}
            errors={errors}
          />

          <h5 className="font-bold">{t('council.m08.b.rank.label')}</h5>
          <ul>
            <li>{t('council.m08.b.rank.excellent')}</li>
            <li>{t('council.m08.b.rank.veryGood')}</li>
            <li>{t('council.m08.b.rank.good')}</li>
            <li>{t('council.m08.b.rank.reached')}</li>
            <li>{t('council.m08.b.rank.notReached')}</li>
          </ul>
          <div className="text-lg mt-3">
            <h5>{t('council.m08.c.conclusion')}</h5>
            <EditorInput name="reviewConclusion" control={control} errors={errors} />
          </div>
          <div className="grid text-lg mt-3">
            <div className="col-7 px-8">
              <span>{t('council.m01.c.commitment')}</span>
            </div>
            <div className="col-5 text-center">
              <p className="mb-0">{t('signature.date')}</p>
              <p className="font-semibold mb-0">{t('signature.reviewer')}</p>
              <p className="mb-7">{t('signature.fullnameAndSignature')}</p>
              <p className="font-bold">{info?.user?.fullname}</p>
            </div>
          </div>

          <div className="flex mt-4 justify-content-end">
            <Button
              label={t('formLayout.action.close')}
              severity="danger"
              type="button"
              className="w-8rem"
              onClick={() => navigate('/project-proposal-defense')}
            />
          </div>
        </form>
      </div>
    </>
  );
}
