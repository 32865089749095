import './style.scss';

import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  downloadPdfFile,
  getDetail,
  getInfo,
  downloadDocxFile,
  downloadDocxTemplate,
  downloadPdfTemplate,
} from 'apis/financialReviews.api';

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

import { DetailInfo, EvaluationDetail } from 'features/FinancialReview/components';

import { TOAST } from 'constant';
import { toast } from 'layout';

import { getAcadamicLevelShortHand, toNonAccentVietnamese } from 'utils/func';
import { ProjectProposalDefenseDetail } from 'features/ProjectProposalDefense/pages';
import { TabPanel, TabView } from 'primereact/tabview';

export default function FinancialReviewDetail() {
  // #region Data
  const { t } = useTranslation();

  const { projectProposalId } = useParams();
  const [searchParams] = useSearchParams();
  const councilId = searchParams.get('councilId');
  const councilMemberId = searchParams.get('councilMemberId');
  const downloadButtonRef = useRef();
  const downloadTemplateButtonRef = useRef();
  const [tab, setTab] = useState(0);

  // A. General Information
  const { data: infoData } = useQuery(
    ['financial-review', 'information', councilId, projectProposalId],
    ({ queryKey: [, , _councilId, _projectProposalId] }) => getInfo(_councilId, _projectProposalId)
  );
  const info = useMemo(() => infoData?.data, [infoData]);

  // B. Review - Evaluation
  // C. Conslusion
  const { data: financialReviewData } = useQuery(
    ['financial-review', 'detail', councilMemberId, councilId, projectProposalId],
    ({ queryKey: [, , _councilMemberId, _councilId, _projectProposalId] }) =>
      getDetail(_councilMemberId, _councilId, _projectProposalId)
  );
  const detail = useMemo(() => financialReviewData?.data, [financialReviewData]);
  // #endregion

  const {
    refetch: handleDownloadPdfFile,
    isLoading: isDownloadPdfFileLoading,
    isFetching: isDownloadPdfFileFetching,
  } = useQuery(
    ['downloadPdfFileFinancialReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            info?.user?.scientificProfile?.academicRank,
            info?.user?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia tham dinh kinh phi - ${academicLevelShorthand}${toNonAccentVietnamese(
            info?.user?.fullname
          )}.pdf`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: (error) => {
        // 490 is the status code for the error
        // when the scientist has not reviewed the online form yet
        if (error?.response?.status === 490) {
          toast(TOAST.ERROR, t('errorMessage.scientistNotReviewOnlineFormYet'));
        } else {
          toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
        }
      },
    }
  );

  const {
    refetch: handleDownloadDocxFile,
    isLoading: isDownloadDocxFileLoading,
    isFetching: isDownloadDocxFileFetching,
  } = useQuery(
    ['downloadDocxFileFinancialReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxFile({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const academicLevelShorthand = getAcadamicLevelShortHand(
            info?.user?.scientificProfile?.academicRank,
            info?.user?.scientificProfile?.academicDegree,
            t,
            true
          );
          const filename = `Phieu nhan xet danh gia tham dinh kinh phi - ${academicLevelShorthand}${toNonAccentVietnamese(
            info?.user?.fullname
          )}.docx`;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: (error) => {
        // 490 is the status code for the error
        // when the scientist has not reviewed the online form yet
        if (error?.response?.status === 490) {
          toast(TOAST.ERROR, t('errorMessage.scientistNotReviewOnlineFormYet'));
        } else {
          toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
        }
      },
    }
  );

  const {
    refetch: handleDownloadPdfTemplate,
    isLoading: isDownloadPdfTemplateLoading,
    isFetching: isDownloadPdfTemplateFetching,
  } = useQuery(
    ['downloadPdfTemplateFinancialReview', projectProposalId, councilId, councilMemberId],
    () => downloadPdfTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M02 - Phieu nhan xet danh gia tham dinh kinh phi.pdf';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportPdfErrorTitle'));
      },
    }
  );

  const {
    refetch: handleDownloadDocxTemplate,
    isLoading: isDownloadDocxTemplateLoading,
    isFetching: isDownloadDocxTemplateFetching,
  } = useQuery(
    ['downloadDocxTemplateFinancialReview', projectProposalId, councilId, councilMemberId],
    () => downloadDocxTemplate({ projectProposalId, councilId, councilMemberId }),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res?.data) {
          const file = new Blob([res.data]);

          const filename = 'Mau M02 - Phieu nhan xet danh gia tham dinh kinh phi.docx';

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = filename;
          link.click();
        }
      },
      onError: () => {
        toast(TOAST.ERROR, t('projectProposal.review.exportDocxErrorTitle'));
      },
    }
  );

  return (
    <TabView activeIndex={tab} onTabChange={(e) => setTab(e.index)}>
      <TabPanel header={t('council.review.viewOrPrint')} leftIcon="pi pi-file mr-2">
        <div className="m-0 bg-white px-4 sm:px-4 md:px-4 lg:px-6 xl:px-8 mb-3 py-5 p-fluid relative financial-review-detail">
          <div className="flex gap-1 justify-content-end">
            <Button
              label={t('council.review.exportFile')}
              icon={`pi ${
                isDownloadPdfFileLoading ||
                isDownloadPdfFileFetching ||
                isDownloadDocxFileLoading ||
                isDownloadDocxFileFetching
                  ? 'pi-spin pi-spinner'
                  : 'pi-file'
              }`}
              className="block w-auto h-3rem border-none"
              severity="info"
              onClick={(event) => downloadButtonRef.current.toggle(event)}
              disabled={
                isDownloadPdfFileLoading ||
                isDownloadPdfFileFetching ||
                isDownloadDocxFileLoading ||
                isDownloadDocxFileFetching
              }
            />
            <Button
              label={t('council.review.downloadTemplate')}
              icon={`pi ${
                isDownloadPdfTemplateLoading ||
                isDownloadPdfTemplateFetching ||
                isDownloadDocxTemplateLoading ||
                isDownloadDocxTemplateFetching
                  ? 'pi-spin pi-spinner'
                  : 'pi-file'
              }`}
              className="block w-auto h-3rem border-none"
              severity="info"
              onClick={(event) => downloadTemplateButtonRef.current.toggle(event)}
              disabled={
                isDownloadPdfTemplateLoading ||
                isDownloadPdfTemplateFetching ||
                isDownloadDocxTemplateLoading ||
                isDownloadDocxTemplateFetching
              }
            />
          </div>

          <Menu
            ref={downloadButtonRef}
            model={[
              {
                label: t('council.review.exportPdf'),
                icon: 'pi pi-file-pdf',
                command: handleDownloadPdfFile,
                disabled: isDownloadPdfFileLoading || isDownloadPdfFileFetching,
              },
              {
                label: t('council.review.exportDocx'),
                icon: 'pi pi-file-word',
                command: handleDownloadDocxFile,
                disabled: isDownloadDocxFileLoading || isDownloadDocxFileFetching,
              },
            ]}
            popup
          />
          <Menu
            ref={downloadTemplateButtonRef}
            model={[
              {
                label: t('council.review.downloadPdfTemplate'),
                icon: 'pi pi-file-pdf',
                command: handleDownloadPdfTemplate,
                disabled: isDownloadPdfTemplateLoading || isDownloadPdfTemplateFetching,
              },
              {
                label: t('council.review.downloadDocxTemplate'),
                icon: 'pi pi-file-word',
                command: handleDownloadDocxTemplate,
                disabled: isDownloadDocxTemplateLoading || isDownloadDocxTemplateFetching,
              },
            ]}
            popup
          />

          <div className="mb-5 flex justify-content-between align-items-center">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="VNU-HCM logo"
              className="ml-3 h-4rem md:h-5rem"
            />
            <div className="pl-2">{t('projectProposal.viewDetail.form')} M02</div>
          </div>
          <div className="text-center mb-6">
            <h3 className="font-bold mb-0">{t('council.m02.title')}</h3>
            <h3 className="mt-0">{t('council.m02.sciTechProject')}</h3>
          </div>

          <DetailInfo info={info} />

          <EvaluationDetail
            review={detail?.financialReview}
            proposedExpenditureSummaries={info?.projectProposal?.proposedExpenditureSummaries}
            projectProposalResearchKpis={info?.projectProposal?.projectProposalResearchKpis}
            submittedAt={info?.projectProposal?.submittedAt}
            expenditureCalculatedByKpi={info?.projectProposal?.proposedExpenditureByKpis}
          />

          <div className="grid text-lg mt-4">
            <div className="col-7 px-8">
              <span>{t('council.m01.c.commitment')}</span>
            </div>
            <div className="col-5 text-center">
              <p className="mb-0">{t('signature.date')}</p>
              <p className="font-semibold mb-0">{t('signature.reviewer')}</p>
              <p className="mb-7">{t('signature.fullnameAndSignature')}</p>
              <p className="font-bold">{info?.user?.fullname}</p>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel header={t('council.review.detailProjectProposal')} leftIcon="pi pi-list mr-2">
        <ProjectProposalDefenseDetail />
      </TabPanel>
    </TabView>
  );
}
